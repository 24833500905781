<template>
  <div class="row m-0 justify-content-center cursor" @click="addItems">
    <div class="col-12 p-2 asset_item" :class="{active: active.link === link, inactive: Object.keys(active).length !== 0 && active.link !== link}">
      <div class="row m-0">
        <div class="col-12 p-0 text-center smallheading">
          {{title}} <ios-information-circle-outline-icon class="small inline-icon cursor" @click.stop.prevent="showInfo = !showInfo"/>
        </div>
        <div class="col-12 p-0" v-if="showInfo">
          <div class="row m-0">
            <div class="col-12 p-0 text-center medium font12 my-2" v-html="info">
            </div>
          </div>
        </div>
        <div class="col-12 p-0" v-else>
          <div class="row m-0">
            <div class="col-12 p-0 text-center bold mt-2">
              {{currency}} {{displayTotal}}
            </div>
            <div class="col-12 p-0 text-center mt-2" v-if="Object.keys(active).length === 0">
              <ios-add-circle-outline-icon class="inline-icon big cursor" @click="addItems"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { cashAssetTypes, investmentAssetType, businessAssetTypes, policiesAssetTypes, debtorsAssetTypes } from '@/api/zakat'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-add-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-add-circle-outline.vue')),
    'ios-information-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-information-circle-outline.vue'))
  },
  name: 'AssetItem',
  props: ['total', 'title', 'info', 'link', 'active', 'type'],
  data () {
    return {
      showInfo: false
    }
  },
  methods: {
    addItems () {
      this.$emit('addItem')
    }
  },
  computed: {
    ...mapGetters([
      'currentZakaah',
      'commoditiesTotal',
      'cashAssetsTotal',
      'policyAssetsTotal',
      'investmentAssetsTotal',
      'businessAssetsTotal',
      'debtorAssetsTotal',
      'partnershipAssetsTotal'
    ]),
    currency () {
      return process.env.VUE_APP_CURRENCY_SYMBOL
    },
    displayTotal () {
      if (this.type !== 'undefined') {
        if (this.currentZakaah.zakaatAssets[this.type]) {
          const itemType = this.type + 'Total'
          return parseFloat(Math.round(this[itemType] * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
      }
      return parseFloat(Math.round(0 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>
<style scoped>
.smallheading {
  color: #fff;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
.asset_item {
  border: 2px solid var(--green-color-dark);
  border-radius: 15px;
  background-color:var( --green-color );
  color: var( --gold-color );
  /* color: #fff; */
}
.asset_item:hover {
  background-color:var( --green-color-light );
}
.asset_item.active {
  background-color:var( --green-color-dark );
}
.asset_item.inactive:hover {
  background-color:var( --green-color );
}
</style>
